export function base64ToBlob(base64) {
  try {
    const type = base64.match(/data:([a-zA-Z/]*);base64/)[1];
    const base64Image = base64.match(/;base64,([a-zA-Z0-9+=/]*)/)[1];

    const byteCharacters = atob(base64Image);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    const blob = new Blob([byteArray], { type });

    return blob;
  } catch (error) {
    return new Blob();
  }
}
