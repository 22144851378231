import { useState } from "react";
import { ReactComponent as Cross } from "../../../../assets/icons/cross.svg";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import { ReactComponent as ChevronDown } from "../../../../assets/icons/cheveron-down.svg";

import "./Collapsable.css";
const Collapsable = ({ label, description, type = 0 }) => {
  const [opened, setOpened] = useState(false);

  const toggle = () => {
    setOpened((oldState) => !oldState);
  };

  return (
    <div className="Collapsable-Wrapper">
      <div className="Collapsable-Header" onClick={toggle}>
        <div className="Collapsable-Header-Label">
          {type === 0 ? (
            <Cross width={20} height={20} />
          ) : (
            <Check width={20} height={20} />
          )}

          <p>{label}</p>
        </div>

        <ChevronDown
          className={`Collapsable-Header-Chevron ${opened ? "opened" : ""}`}
        />
      </div>

      <div
        className="Collapsable-Content"
        style={{ maxHeight: opened ? "40em" : "0" }}
      >
        <p>{description}</p>
      </div>
    </div>
  );
};

export default Collapsable;
