export const OverlayStore = (set, get) => ({
  show: false,
  OverlayElement: null,
  showOverlay: (element) =>
    set(
      (state) => {
        return { ...state, show: true, OverlayElement: element };
      },
      false,
      "images/showOverlay"
    ),
  hideOverlay: () =>
    set(
      (state) => {
        return { ...state, show: false, OverlayElement: null };
      },
      false,
      "images/hideOverlay"
    ),
});
