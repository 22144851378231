import "./loader.css";
import { ReactComponent as LoaderSVG } from "../../assets/icons/loader.svg";
import { useEffect, useState } from "react";

const Loader = ({ show, shadow = true }) => {
  console.log(show);
  const [display, setDisplay] = useState(show);

  useEffect(() => {
    if (show) {
      setDisplay(true);
    }
  }, [show]);

  const onAnimationEnd = (evt) => {
    if (evt.target.classList.contains("fade-out")) {
      setDisplay(false);
    }
  };
  return (
    <div
      className={`Loader-Wrapper ${display ? "display" : ""} ${
        shadow ? "shadow" : ""
      } ${show ? "fade-in" : "fade-out"}`}
      onAnimationEnd={onAnimationEnd}
    >
      <LoaderSVG className={"rotate"} />
    </div>
  );
};

export default Loader;
