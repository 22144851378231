import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { OverlayStore } from "./store";

export const useOverlayStore = create(
  devtools(OverlayStore, {
    name: "OverlayStore",
    store: "OverlayStore",
    trace: true,
  })
);
