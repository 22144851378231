import { analyzeImage, getSafe, sendSafe } from "../../helper/api";
import { base64ToBlob } from "../../helper/base64ToBlob";

export const IMAGE_STORE_STATUS = Object.freeze({
  IDLE: 1,
  LOADING: 2,
  FAILED: 3,
  LOADED: 4,
});

export const ImageStore = (set, get) => ({
  status: IMAGE_STORE_STATUS.IDLE,
  imagesCount: 0,
  images2: {},
  order: [],
  selected: null,
  addImage: async (image, token) => {
    const order = [...get().order];
    const currentImages = { ...get().images2 };

    const id = window.crypto.randomUUID();
    const selected = id;

    // add image to local store
    currentImages[id] = {
      id,
      analyzed: false,
      progress: 0,
      name: image.name,
      date: new Date().toString(),
      image: image.image,
      size: image.size,
      url: image.url,
      persons: [],
      photoQuality: null,
    };

    order.unshift(id);

    set(
      (state) => {
        return {
          ...state.images,
          selected,
          imagesCount: state.imagesCount + 1,
          images2: currentImages,
          order,
        };
      },
      false,
      "images/addImage"
    );

    const a = image.size / 1000;
    const b = Math.trunc(a / 100) + 1;

    const timer = setInterval(() => {
      const images = get().images2;

      if (!images[id]) {
        clearInterval(timer);
        return;
      }

      const progress = images[id].progress;
      if (progress < 90) {
        get().updateImageProgress(id, progress + 1);
        return;
      }

      clearInterval(timer);
    }, (b * 6000) / 90);

    // send image to be analyzed
    get().analyzeImage(id, image, token);
  },
  deleteImage: (id, token) =>
    set(
      (state) => {
        const images2 = { ...state.images2 };
        const order = [...state.order];
        const index = order.indexOf(id);

        order.splice(index, 1);
        delete images2[id];

        let selected = null;

        if (state.selected !== id) {
          selected = state.selected;
        } else {
          if (index >= order.length) {
            if (order.length !== 0) {
              let newIndex = order.length - 1;
              selected = order[newIndex];
            }
          } else {
            selected = order[index];
          }
        }

        const imagesArr = Object.entries(images2).map((entry) => entry[1]);
        sendSafe(imagesArr, token);

        return {
          ...state,
          selected,
          order,
          images2,
          imagesCount: state.imagesCount - 1,
        };
      },
      false,
      "images/deleteImage"
    ),
  selectImage: (id) =>
    set(
      (state) => {
        if (id === state.selected) {
          return state;
        }

        let selected = null;
        const imageCount = Object.keys(state.order).length;
        const index = state.order.indexOf(id);

        if (index >= imageCount) {
          let newIndex = imageCount - 1;
          selected = state.order[newIndex];
        } else {
          selected = state.order[index];
        }

        return { ...state, selected };
      },
      false,
      "images/selectImage"
    ),
  loadImagesFromDatabase: async (token) => {
    const currentImages = { ...get().images2 };
    try {
      const safe = await getSafe(token);

      safe.forEach((val, i) => {
        const url = URL.createObjectURL(base64ToBlob(val.image));

        if (val.persons.length !== 0) {
          const persons = val.persons.map((person) => {
            person.url = URL.createObjectURL(base64ToBlob(person.image));
            return person;
          });

          currentImages[val.id] = {
            ...val,
            persons: persons,
            url,
          };
        }
      });

      let sortedImageIds = Object.entries(currentImages)
        .map((entry) => entry[0])
        .reverse();

      // Update Store with images from safe
      set(
        (state) => {
          const selected =
            state.selected === null && sortedImageIds.length > 0
              ? sortedImageIds[0]
              : null;

          return {
            ...state,
            status: IMAGE_STORE_STATUS.LOADED,
            imagesCount: safe.length,
            images2: currentImages,
            selected,
            order: sortedImageIds,
          };
        },
        false,
        "images/loadImagesFromDatabase"
      );
    } catch (err) {
      console.log(err);
      set(
        (state) => {
          return {
            ...state,
            status: IMAGE_STORE_STATUS.FAILED,
          };
        },
        false,
        "images/loadImagesFromDatabase"
      );
    }
  },
  clearImagesStore: () => {
    set((state) => ({
      ...state,
      status: IMAGE_STORE_STATUS.IDLE,
      imagesCount: 0,
      images2: {},
      order: [],
      selected: null,
    }));
  },
  analyzeImage: async (id, image, token) => {
    const result = await analyzeImage(image.file, token)
      .then((res) => {
        return {
          ...res,
          analyzed: true,
        };
      })
      .catch((err) => {
        return {
          analyzed: false,
          persons: [],
          photoQuality: null,
        };
      });

    console.log(result);

    if (!result.analyzed || result.error) {
      set(
        (state) => {
          if (!state.images2[id]) {
            return {
              ...state,
            };
          }
          const currentImages = state.images2;
          currentImages[id] = {
            ...currentImages[id],
            persons: [],
            photoQuality: null,
            progress: 100,
            analyzed: true,
          };

          return {
            ...state,
            images2: currentImages,
          };
        },
        false,
        "images/addImage"
      );

      return;
    }

    if (result.persons.length !== 0) {
      result.persons = result.persons.map((person) => {
        person.url = URL.createObjectURL(base64ToBlob(person.image));

        return person;
      });
    }

    set(
      (state) => {
        if (!state.images2[id]) {
          return {
            ...state,
          };
        }
        const currentImages = state.images2;

        currentImages[id] = {
          ...currentImages[id],
          progress: result.analyzed ? 100 : currentImages[id].progress,
          ...result,
        };

        const newState = {
          ...state,
          images2: currentImages,
        };

        // save result in Store
        let sortedImageIds = Object.entries(currentImages).map(
          (entry) => entry[1]
        );
        sendSafe(sortedImageIds, token);

        return newState;
      },
      false,
      "images/addImage"
    );
  },
  getImageById: (id) => {
    const images = get().images2;
    return images[id];
  },
  updateImageProgress: (id, newProgress) => {
    const images = get().images2;
    const image = {
      ...images[id],
      progress: newProgress,
    };

    images[id] = image;

    set(
      (state) => {
        return {
          ...state,
          images2: images,
        };
      },
      false,
      "images/addImage"
    );
  },
});
