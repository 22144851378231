import { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/AuthContext";
import { baseUrl, registerUser, loginUser, sendEmail } from "../../helper/api";
import { encryptionModule } from "../../helper/encryption";
import { validateEmail822 } from "../../helper/helper";

import { IMAGE_STORE_STATUS } from "../../stores/ImageStore/store";
import { useImagesStore } from "../../stores/ImageStore";

import { ReactComponent as Logo } from "../../assets/Logo.svg";

import "./login.css";
import Loader from "../../components/Loader";

function Login() {
  const { login } = useContext(AuthContext); //Enable the use of the AuthContext
  const imagesStoreState = useImagesStore((state) => state.status);
  const loadImagesFromDatabase = useImagesStore(
    (state) => state.loadImagesFromDatabase
  );

  const [loader, setLoader] = useState(false);
  const [form, setForm] = useState("login");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [successMessage, setSuccessMessage] = useState(null);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (errors.box) {
      resetError("box");
    }
  }, [form]);

  const resetError = (field) => {
    setErrors((currentErrors) => {
      return { ...currentErrors, ...{ [field]: "" } };
    });
  };

  const onClickLogin = (event) => {
    setSuccessMessage(null);
    setPassword("");
    setErrors({});
    event.preventDefault();
    setForm("login");
  };

  const onClickRegister = (event) => {
    setPassword("");
    setErrors({});
    event.preventDefault();
    setForm("register");
  };

  const onClickPasswordForgotten = (event) => {
    setErrors({});
    event.preventDefault();
    setForm("forgotten");
  };

  const onInputName = (event) => {
    setName(event.target.value);

    if (errors.name) {
      resetError("name");
    }
  };

  const onInputEmail = (event) => {
    setEmail(event.target.value);

    if (errors.email) {
      resetError("email");
    }
  };

  const onInputPassword = (event) => {
    setPassword(event.target.value);

    if (errors.password) {
      resetError("password");
    }
  };

  const onInputPasswordConfirm = (event) => {
    setPasswordConfirm(event.target.value);

    if (errors.passwordConfirm) {
      resetError("passwordConfirm");
    }
  };

  const validateInput = () => {
    //Checking the input from the user
    const errorMsgs = {};

    if (password.length < 8 && form !== "forgotten") {
      errorMsgs["password"] = "must have at least 8 characters.";
    }

    if (!validateEmail822(email)) {
      errorMsgs["email"] = "is not valid!";
    }

    if (password !== passwordConfirm && form === "register") {
      errorMsgs["passwordConfirm"] = "is not equal!";
    }

    if (name.length === 0 && form === "register") {
      errorMsgs["name"] = "can not be empty.";
    }

    setErrors((currentErrors) => {
      return {
        ...currentErrors,
        ...errorMsgs,
      };
    });

    return Object.keys(errorMsgs).length !== 0;
  };

  const handleRegister = async () => {
    const response = await registerUser(email, name, password);

    if (response === 200) {
      await handleLogin();
      return;
    }

    setErrors((currentErrors) => {
      return {
        ...currentErrors,
        box: "Cannot create new account",
      };
    });
  };

  const handleLogin = async () => {
    const response = await loginUser(email);

    if (response.toString().trim().length === 3) {
      setErrors((currentErrors) => {
        return {
          ...currentErrors,
          box: "Wrong Password or E-Mail!",
        };
      });

      return;
    }

    if (imagesStoreState === IMAGE_STORE_STATUS.IDLE) {
      await loadImagesFromDatabase(response);
    }

    login(response, email, password);
  };

  const handlePasswordReset = async () => {
    setLoader(true);

    await sendEmail(email)
      .then(() => {
        setSuccessMessage("An email has been sent!");
      })
      .catch(() => {
        setSuccessMessage("An email has been sent!");
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const onSubmit = async () => {
    //Register, login and reset password in one function
    setLoader(true);

    if (validateInput()) {
      setLoader(false);
      return;
    }

    try {
      await encryptionModule.initialise(email, password); //Initialise with the data from the user

      switch (form) {
        case "register":
          await handleRegister();
          break;
        case "login":
          await handleLogin();
          break;
        case "forgotten":
          await handlePasswordReset();
          break;
        default:
          setLoader(false);
          break;
      }
    } catch (err) {
      console.log(err);
      let errorMsg = "Unknown error occured!";
      if (err === 401) {
        errorMsg = "Wrong password or email";
      }
      setErrors((currentErrors) => {
        return { ...currentErrors, box: errorMsg };
      });
      setLoader(false);
    }
  };

  return (
    //Dynamic representation
    <div className="Login-Container">
      <div className="Login-Field">
        <Logo className="Main-Logo App-Logo" />

        <Loader show={loader} />

        <div className="Login-Error-Box">
          {errors.box && (
            <>
              <span className="Login-Error-Message">{errors.box}</span>
              <a href={baseUrl} onClick={onClickPasswordForgotten}>
                Password forgotten
              </a>
            </>
          )}
        </div>
        <div className="Login-Input">
          {form === "register" && (
            <>
              <div className="Login-Input-Label-Wrapper">
                <label className="Login-Label">Name</label>

                {errors.name && (
                  <>
                    <span className="Login-Error-Message">{errors.name}</span>
                  </>
                )}
              </div>
              <input
                className={`Login-Inputfield ${
                  errors.name ? "Login-Input-Error" : ""
                }`}
                type="text"
                placeholder="Your Name"
                onInput={onInputName}
                onKeyDown={(event) => event.key === "Enter" && onSubmit()}
              />
            </>
          )}
          <div className="Login-Input-Label-Wrapper">
            <label className="Login-Label">E-Mail</label>

            {errors.email && (
              <>
                <span className="Login-Error-Message">{errors.email}</span>
              </>
            )}
          </div>

          <input
            className={`Login-Inputfield ${
              errors.email ? "Login-Input-Error" : ""
            }`}
            type="email"
            placeholder="example@example.com"
            onInput={onInputEmail}
            onKeyDown={(event) => event.key === "Enter" && onSubmit()}
          />

          {form !== "forgotten" && (
            <>
              <div className="Login-Input-Label-Wrapper">
                <label className="Login-Label">Password</label>

                {errors.password && (
                  <>
                    <span className="Login-Error-Message">
                      {errors.password}
                    </span>
                  </>
                )}
              </div>

              <input
                className={`Login-Inputfield ${
                  errors.password ? "Login-Input-Error" : ""
                }`}
                value={password}
                type="password"
                placeholder="password"
                onInput={onInputPassword}
                onKeyDown={(event) => event.key === "Enter" && onSubmit()}
              />
            </>
          )}

          {form === "register" && (
            <>
              <div className="Login-Input-Label-Wrapper">
                <label className="Login-Label">Password confirm</label>
                {errors.passwordConfirm && (
                  <>
                    <span className="Login-Error-Message">
                      {errors.passwordConfirm}
                    </span>
                  </>
                )}
              </div>
              <input
                className={`Login-Inputfield ${
                  errors.passwordConfirm ? "Login-Input-Error" : ""
                }`}
                type="password"
                placeholder="password"
                onInput={onInputPasswordConfirm}
                onKeyDown={(event) => event.key === "Enter" && onSubmit()}
              />
            </>
          )}

          <button className="Login-Button" onClick={onSubmit}>
            {form === "register" && "Register"}
            {form === "login" && "Login"}
            {form === "forgotten" && "Send E-Mail"}
          </button>

          {successMessage && (
            <p className="Reset-Success-Message">{successMessage}</p>
          )}
          {form === "register" && (
            <p className="Login-Info-Text">
              You already have an Account?{" "}
              <a href={baseUrl} onClick={onClickLogin}>
                Login
              </a>
            </p>
          )}
          {form === "login" && (
            <p className="Login-Info-Text">
              You don't have an Account?{" "}
              <a href={baseUrl} onClick={onClickRegister}>
                Register now!
              </a>
            </p>
          )}
          {form === "forgotten" && (
            <p className="Login-Info-Text">
              You can remember your account?{" "}
              <a href={baseUrl} onClick={onClickLogin}>
                Login
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
