import React from "react";
import Catch from "./functional-error-boundary";

const ErrorCatcher = Catch(function ErrorCatcher(props, error) {
  if (error) {
    return (
      <div>
        <p>An unknown error occured! Please try again later.</p>
      </div>
    );
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
});

export default ErrorCatcher;
