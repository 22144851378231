import "./UploadField.css";

import { ReactComponent as Logo } from "../../../../assets/icons/upload.svg";
import { useImagesStore } from "../../../../stores/ImageStore";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";

const ALLOWED_FORMATS = new Set([
  "image/webp",
  "image/png",
  "image/jpeg",
  "image/giff",
]);

const UploadField = ({ className }) => {
  const addImage = useImagesStore((state) => state.addImage);
  const { authState } = useContext(AuthContext);

  const handleFileInput = (evt) => {
    if (evt.target.files.length !== 1) {
      return;
    }

    const file = evt.target.files[0];

    if (!ALLOWED_FORMATS.has(file.type)) {
      alert("The file type is not supported");
      return;
    }

    /*
    if (file.size >= 2306867) {
      alert("The image must not be larger than 2.2 MB");
      return;
    }
    */

    const reader = new FileReader();

    reader.onload = (e) => {
      console.log(file.size);
      addImage(
        {
          name: file.name,
          size: file.size,
          lastModified: file.lastModified,
          image: e.target.result,
          file: file,
          url: URL.createObjectURL(file),
        },
        authState
      );
    };

    reader.readAsDataURL(file);
  };

  return (
    <div className={className}>
      <div className="UploadField-Container">
        <input
          type="file"
          name="image"
          className="UploadField-Input"
          onChange={handleFileInput}
          onClick={(event) => {
            event.target.value = null;
          }}
          onMouseEnter={(event) => {
            event.target.value = null;
          }}
        />

        <div className="UploadField-Logo">
          <Logo />
        </div>
        <p>Drag and Drop your files here</p>
      </div>
    </div>
  );
};

export default UploadField;
