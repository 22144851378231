import { createContext, useEffect, useState } from "react";
import { loginUser } from "../helper/api";
import { useImagesStore } from "../stores/ImageStore";

export const AuthContext = createContext(); //Generates a context that can be imported

export const AuthProvider = ({ children }) => {
  //Adopts the login logic of the programme when to display the dashboard and when to display the login page
  const [authState, setAuthState] = useState(undefined);
  const [userEmail, setUserEmail] = useState(undefined);
  const [userPassword, setUserPassword] = useState(undefined);

  const clearImagesStore = useImagesStore((state) => state.clearImagesStore);

  const login = (token, email, password) => {
    setUserEmail(email);
    setUserPassword(password);
    setAuthState(token);
  };

  const logout = () => {
    setAuthState(undefined);
    setUserEmail(undefined);
    setUserPassword(undefined);

    clearImagesStore();
  };

  useEffect(() => {
    if (!userEmail && !userPassword) {
      return;
    }
    const myTimout = setTimeout(() => {
      console.log("Getting new TOKEN!");
      loginUser(userEmail).then((x) => login(x, userEmail, userPassword));
    }, 30000);

    return () => clearTimeout(myTimout);
  }, [authState, userEmail, userPassword]);

  return (
    <AuthContext.Provider
      value={{
        authState,
        userEmail,
        userPassword,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
