import React from "react";
import ReactDOM from "react-dom";
import App from "./screens/App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./context/AuthContext";

import "./index.css";

ReactDOM.render(
  //Render logic from the website
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

reportWebVitals();
