import "./overlay.css";

import { useOverlayStore } from "../../stores/OverlayStore/useOverlayStore";
import { useEffect, useState } from "react";

const OverlayRoot = () => {
  const show = useOverlayStore((state) => state.show);
  const OverlayContent = useOverlayStore((state) => state.OverlayElement);
  const hideOverlay = useOverlayStore((state) => state.hideOverlay);
  const [display, setDisplay] = useState(show);

  useEffect(() => {
    if (show) {
      setDisplay(true);
    }
  }, [show]);

  const onClickOutside = () => {
    console.log("Click outside");
    hideOverlay();
  };

  const onClickInside = (evt) => {
    console.log("Click inside");
    evt.stopPropagation();
  };

  const transitionEnd = (evt) => {
    if (!evt.target.classList.contains("Overlay-Wrapper")) {
      return;
    }
    if (evt.target.classList.contains("fade-out")) {
      setDisplay(false);
    }
  };

  if (!display || !OverlayContent) {
    return <></>;
  }

  return (
    <div
      onClick={onClickOutside}
      onAnimationEnd={transitionEnd}
      className={`Overlay-Wrapper ${show ? "" : "fade-out"}`}
    >
      <div onClick={onClickInside}>
        <OverlayContent />
      </div>
    </div>
  );
};

export { OverlayRoot };
