import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { ImageStore } from "./store";

export const useImagesStore = create(
  devtools(ImageStore, {
    name: "ImageStore",
    store: "ImageStore",
    trace: true,
  })
);

/* 
 {
    id: 'asdfgfdghmggershdjfgjhhrteghjghjhjhk',
    name: 'My Summer Holiday.jpg',
    date: '2022-03-13T34',
    image: 'base64',
    persons: [
        {
            image: 'base64',
            sympathie: {
                value: 100,
            },
            quality: {
                value: 100,
                description: 'kauf eine Spiegelreflex',
            },
            beauty: {
                value: 100,
                description: 'du bist einfach hässlich',
            },
            emotions: {
                value: 100,
                description: 'lach mal',
            },
            posture: {
                value: 100,
                description: 'steh gerade',
            },
        }
    ],
    

},
*/
