import "./HistoryList.css";

import {
  IMAGE_STORE_STATUS,
  useImagesStore,
} from "../../../../stores/ImageStore";
import HistoryListItem from "./HistoryListItem/HistoryListItem";

import { ReactComponent as ArrowUp } from "../../../../assets/icons/chevron-up.svg";

const HistoryList = ({ className, toggle }) => {
  const status = useImagesStore((state) => state.status);
  const sortedImageIds = useImagesStore((state) => state.order);
  let message = null;

  if (sortedImageIds.length === 0) {
    message = "You don't have any images.";

    if (status === IMAGE_STORE_STATUS.FAILED) {
      message = "Failed to load images from database.";
    }
  }

  return (
    <div className={className}>
      <div onClick={toggle} className={`HistoryList-Header`}>
        <h2>Your history</h2>
        <button className="HistoryList-Toggle">
          <ArrowUp />
        </button>
      </div>
      <div className="HistoryList-Seperator"></div>

      <div className="HistoryList-Items">
        {message !== null ? (
          <p className="HistoryList-Message">{message}</p>
        ) : (
          sortedImageIds.map((id, index) => {
            return (
              <HistoryListItem key={id} id={id} index={index} toggle={toggle} />
            );
          })
        )}
      </div>
    </div>
  );
};

export default HistoryList;
