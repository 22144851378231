import { useImagesStore } from "../../../../../stores/ImageStore";
import { ReactComponent as DeleteIcon } from "../../../../../assets/icons/delete.svg";

import "./HistoryListItem.css";
import { fileSizeToString } from "../../../../../helper/fileSizeToString";
import { useContext } from "react";
import { AuthContext } from "../../../../../context/AuthContext";
import Loader from "../../../../../components/Loader";

var options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

const formatDate = (date) => date.toLocaleDateString("de-DE", options);

const HistoryListItem = ({ id, index, toggle }) => {
  const selectImage = useImagesStore((state) => state.selectImage);
  const deleteImage = useImagesStore((state) => state.deleteImage);
  const selected = useImagesStore((state) => state.selected);
  const item = useImagesStore((state) => state.images2[id]);
  const { authState } = useContext(AuthContext);

  const imageName = item.name;
  const imageUrl = item.url;
  const fileSize = fileSizeToString(item.size);

  const onClick = (evt) => {
    selectImage(id);
    toggle();
  };

  const onDelete = (evt) => {
    evt.stopPropagation();
    deleteImage(id, authState);
  };

  const fade = item.progress === 100 ? "fade" : "";

  return (
    <div
      onClick={onClick}
      className={`HistoryListItem-Container ${
        selected === item.id ? "HistoryListItem-Container-Selected" : ""
      }`}
    >
      <div className="HistoryListItem-Group">
        <div className="HistoryListItem-Image">
          <div className="HistoryListItem-Image-Loader">
            <Loader show={!item.analyzed} />
          </div>
          <img src={imageUrl} alt={imageName} />
        </div>
        <div className={`HistoryListItem-Description`}>
          <span className={`HistoryListItem-Title`}>{item.name}</span>

          <div className={`HistoryListItem-Details`}>
            <span className={`HistoryListItem-Detail`}>
              {formatDate(new Date(item?.date))}
            </span>
            <span className={`HistoryListItem-Detail`}> - </span>
            <span className={`HistoryListItem-Detail`}>{fileSize}</span>
          </div>
        </div>
      </div>

      <DeleteIcon className={`HistoryListItem-Delete`} onClick={onDelete} />
      <div
        className={`HistoryListItem-Progressbar ${fade}`}
        style={{ width: `${item.progress}%` }}
      />
    </div>
  );
};

export default HistoryListItem;
