import "./settingsOverlay.css";

import { useContext, useRef, useState } from "react";
import { useOverlayStore } from "../../../../stores/OverlayStore/useOverlayStore";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/close.svg";
import {
  changeEmail,
  changePassword,
  deleteAccountRequest,
  sendSafe,
} from "../../../../helper/api";
import { AuthContext } from "../../../../context/AuthContext";
import { useImagesStore } from "../../../../stores/ImageStore";
import { validateEmail822 } from "../../../../helper/helper";
import Loader from "../../../../components/Loader";
import { encryptionModule } from "../../../../helper/encryption";

const SECTIONS = Object.freeze({
  INFO: 1,
  DELETE_ACCOUNT: 2,
  CHANGE_EMAIL: 3,
  CHANGE_PASSWORD: 4,
});

const InfoSection = ({ setLoader }) => {
  const { userEmail } = useContext(AuthContext);
  const imageCount = useImagesStore((state) => state.imagesCount);

  return (
    <div className="SettingsOverlay-Info-Section">
      <p>Email: {userEmail}</p>
      <p>
        You have uploaded <b>{imageCount}</b> images!
      </p>
    </div>
  );
};

const DeleteAccountSection = ({ setLoader }) => {
  const { authState, logout } = useContext(AuthContext);

  const onSubmit = async (evt) => {
    setLoader(true);

    await deleteAccountRequest(authState)
      .then(() => {
        logout();
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };

  return (
    <button className="Login-Button" onClick={onSubmit}>
      delete now
    </button>
  );
};

const ChangeEmailSection = ({ setLoader }) => {
  const { authState, logout, userPassword, userEmail } =
    useContext(AuthContext);
  const inputRef = useRef();
  const [error, setError] = useState(null);

  const images2 = useImagesStore((state) => state.images2);

  const onSubmit = async (evt) => {
    setLoader(true);
    if (!inputRef || inputRef?.current?.value === userEmail) {
      setError("Cannot set email address");
      setLoader(false);
      return;
    }

    const newEmail = inputRef.current.value;

    if (!validateEmail822(newEmail)) {
      setError("Invalid email address");
      setLoader(false);
      return;
    }

    const imagesArr = Object.entries(images2).map((entry) => entry[1]);

    await changeEmail(imagesArr, newEmail, userPassword, authState)
      .then(async () => {
        console.log("loging out");
        logout();
      })
      .catch(async (err) => {
        await encryptionModule.initialise(userEmail, userPassword); //Initialise with the old data

        await sendSafe(imagesArr, authState);
        console.log(err);
        setError("Could not change E-Mail!");
        setLoader(false);
      });
  };

  return (
    <>
      <p>
        Enter your new E-Mail:<br></br>
      </p>

      <input
        ref={inputRef}
        className={`Login-Inputfield`}
        type="text"
        placeholder="example@mail.com"
      />
      <button onClick={onSubmit}>Save</button>
      {error && <p className="Error">{error}</p>}
    </>
  );
};

const ChangePasswordSection = ({ setLoader }) => {
  const { authState, logout, userPassword, userEmail } =
    useContext(AuthContext);
  const inputRef = useRef();
  const [error, setError] = useState(null);

  const images2 = useImagesStore((state) => state.images2);

  const onSubmit = async (evt) => {
    setLoader(true);

    if (!inputRef) {
      setLoader(false);
      return;
    }

    const newPassword = inputRef.current.value;

    if (newPassword.length < 8) {
      setError("Password must have at least 8 characters.");
      setLoader(false);
      return;
    }

    const imagesArr = Object.entries(images2).map((entry) => entry[1]);

    await changePassword(imagesArr, userEmail, newPassword, authState)
      .then(() => {
        logout();
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        //setError(err);
      });
  };

  return (
    <>
      <p>
        Enter your new password:<br></br>
      </p>

      <input
        ref={inputRef}
        className={`Login-Inputfield`}
        type="text"
        placeholder="password"
      />

      <button onClick={onSubmit}>Save</button>
      {error && <p className="Error">{error}</p>}
    </>
  );
};

const Main = ({ section, setLoader }) => {
  const hideOverlay = useOverlayStore((state) => state.hideOverlay);

  return (
    <div className="SettingsOverlay-Content">
      <div className="SettingsOverlay-Content-Header">
        <p className="SettingsOverlay-Content-Header-Title">{section.title}</p>
        <button onClick={() => hideOverlay()}>
          <CloseIcon />
        </button>
      </div>

      <div className="SettingsOverlay-Content-Main">
        {section.id === SECTIONS.INFO && <InfoSection setLoader={setLoader} />}
        {section.id === SECTIONS.DELETE_ACCOUNT && (
          <DeleteAccountSection setLoader={setLoader} />
        )}
        {section.id === SECTIONS.CHANGE_EMAIL && (
          <ChangeEmailSection setLoader={setLoader} />
        )}
        {section.id === SECTIONS.CHANGE_PASSWORD && (
          <ChangePasswordSection setLoader={setLoader} />
        )}
      </div>
    </div>
  );
};

const options = [
  { title: "Your Info", id: SECTIONS.INFO },
  { title: "Change E-Mail", id: SECTIONS.CHANGE_EMAIL },
  { title: "Change Password", id: SECTIONS.CHANGE_PASSWORD },
  { title: "Delete Account", id: SECTIONS.DELETE_ACCOUNT },
];

export const SettingsOverlay = () => {
  const [section, setSection] = useState(options[0]);
  const [loader, setLoader] = useState(false);
  const hideOverlay = useOverlayStore((state) => state.hideOverlay);

  return (
    <div className="SettingsOverlay-Wrapper">
      <Loader show={loader} />
      <div className="SettingsOverlay-Sidebar">
        <div className="SettingsOverlay-Sidebar-Title-Wrapper">
          <p className="SettingsOverlay-Sidebar-Title">Settings</p>
          <button onClick={() => hideOverlay()}>
            <CloseIcon />
          </button>
        </div>

        {options.map((btn) => {
          return (
            <button
              key={btn.id}
              onClick={() => setSection(btn)}
              className="SettingsOverlay-Sidebar-Item"
            >
              {btn.title}
            </button>
          );
        })}
      </div>

      <Main section={section} setLoader={setLoader} />
    </div>
  );
};
