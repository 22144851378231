import { ReactComponent as Logo } from "../../../../assets/Logo.svg";
import { ReactComponent as SettingsIcon } from "../../../../assets/icons/settings.svg";
import { ReactComponent as LogoutIcon } from "../../../../assets/icons/logout.svg";

import "./Header.css";
import { useContext } from "react";
import { AuthContext } from "../../../../context/AuthContext";
import { useOverlayStore } from "../../../../stores/OverlayStore/useOverlayStore";
import { SettingsOverlay } from "../SettingsOverlay";

const Header = ({ className }) => {
  const { logout, authState } = useContext(AuthContext);
  const showOverlay = useOverlayStore((state) => state.showOverlay);

  const onClickSettings = () => {
    showOverlay(SettingsOverlay);
  };

  return (
    <nav className={className}>
      <a href="." className="App-Logo">
        <Logo className="App-Logo" />
      </a>
      <div className="Header-Menu">
        <SettingsIcon className="Header-Menu-Item" onClick={onClickSettings} />

        <LogoutIcon className="Header-Menu-Item" onClick={logout} />
      </div>
    </nav>
  );
};

export default Header;
