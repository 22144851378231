import { useEffect, useState } from "react";
import ErrorCatcher from "../../../../components/ErrorCatcher";
import Loader from "../../../../components/Loader";
import { useImagesStore } from "../../../../stores/ImageStore";
import { ReactComponent as SadImage } from "../../../../assets/sad.svg";
import { ReactComponent as PreviewImage } from "../../../../assets/icons/preview.svg";
import RankingSection from "../Ranking";

import "./Main.css";

const Tutorial = () => {
  return (
    <div className="Main-Preview">
      <PreviewImage className="Main-Preview-Image" />
      <p>
        This service allows you to have your pictures rated according to
        likeability. The rating is done by an artificial intelligence. Security
        is important to us, so your pictures will be deleted after the analysis.
        Only you can see the history! Attention: the opinion of the algorithm is
        not generally valid - likeability is subjective.
      </p>
    </div>
  );
};

const PersonsSection = ({ image, selectedPerson, setSelectedPerson }) => {
  const persons = image.persons;
  return (
    <div className="Main-Persons-Section">
      <h2>Persons</h2>
      <div className="Main-Persons-Section-Person-Wrapper">
        {[...persons].map((person, index) => (
          <div
            onClick={() => setSelectedPerson(index)}
            key={index}
            className={`Main-Persons-Section-Person ${
              selectedPerson === index && "Main-Persons-Section-Selected-Person"
            }`}
          >
            <img src={persons[index].url} alt={person.name} draggable={false} />
          </div>
        ))}
      </div>
    </div>
  );
};

const SelectedImageDetails = ({ id }) => {
  const [selectedPerson, setSelectedPerson] = useState(0);
  const image = useImagesStore((state) => state.images2[id]);

  useEffect(() => {
    setSelectedPerson(0);
  }, [id]);

  console.log(image.persons);
  return (
    <>
      <div className="Main-Image-Wrapper">
        <img className="Main-Image" src={image.url} alt={image.name} />
      </div>

      {image.analyzed ? (
        <>
          {image.persons.length === 0 ? (
            <div className="Main-Error-Wrapper">
              <SadImage className="Main-Error-Image" />
              <p className="Main-Error-Message">
                The AI couldn't find any valid faces on this Image.
              </p>
            </div>
          ) : (
            <>
              <PersonsSection
                image={image}
                selectedPerson={selectedPerson}
                setSelectedPerson={setSelectedPerson}
              />
              <RankingSection image={image} selectedPerson={selectedPerson} />
            </>
          )}
        </>
      ) : (
        <div style={{ position: "relative", height: "100%" }}>
          <Loader show={true} shadow={false} />
        </div>
      )}
    </>
  );
};

const Main = ({ className }) => {
  const selected = useImagesStore((state) => state.selected);

  return (
    <ErrorCatcher>
      <div className={className}>
        {selected ? <SelectedImageDetails id={selected} /> : <Tutorial />}
      </div>
    </ErrorCatcher>
  );
};

export default Main;
