import { ReactComponent as ProgressBar } from "../../../../assets/icons/bar.svg";
import { colorByPercentage } from "../../../../helper/colorByPercentage";
import Collapsable from "../Collapsable";

import "./Ranking.css";

const ScoreBar = ({ label, value }) => {
  console.log();
  return (
    <div className="ScoreBar">
      <div className="ScoreBar-Label">
        <p style={{ color: `#00143C` }}> {label} </p>
        <p style={{ color: `${colorByPercentage(value)}` }}> {value} </p>
      </div>
      <div
        className="ScoreBar-Progress-Wrapper"
        style={{
          background: `${colorByPercentage(value, true)}`,
        }}
      >
        <div
          className="ScoreBar-Progress"
          style={{
            width: `${value + 2}%`,
            background: `${colorByPercentage(value)}`,
          }}
        />
      </div>
    </div>
  );
};

const Scores = ({
  sympathy,
  imageQuality,
  beauty,
  bodyPosture,
  facialEmotions,
}) => {
  return (
    <div className="Scores-Wrapper" style={{ gridArea: "b" }}>
      <div>
        <div className="ScoreBar-Sympathy">
          <div className="ProgressBar-Wrapper">
            <ProgressBar
              className="ProgressBar-Background"
              style={{ color: `${colorByPercentage(sympathy.score, true)}` }}
            />
            <ProgressBar
              className="ProgressBar-Value"
              style={{
                strokeDashoffset: `${180 - (180 * sympathy.score) / 100}px`,
                color: `${colorByPercentage(sympathy.score)}`,
              }}
            />
          </div>

          <p style={{ color: `${colorByPercentage(sympathy.score)}` }}>
            {sympathy.score}
          </p>
        </div>
        <p>Sympathy</p>
      </div>

      <ScoreBar label={"Image Quality"} value={imageQuality} />
      <ScoreBar label={"Beauty"} value={beauty.score} />
      <ScoreBar label={"Body Posture"} value={bodyPosture.score} />
      <ScoreBar label={"Facial Emotions"} value={facialEmotions} />
    </div>
  );
};

const Emotion = ({ name }) => {
  const id = name.replaceAll(" ", "_");
  return (
    <div className="Main-Rankings-Attribute">
      <img
        src={`emotions/${id.toLowerCase()}.svg`}
        alt={""}
        width={30}
        height={30}
      />
      <p>{name}</p>
    </div>
  );
};

const Attribute = ({ name }) => {
  const id = name.replaceAll(" ", "_");
  return (
    <div className="Main-Rankings-Attribute">
      <img
        src={`attributes/${id.toLowerCase()}.svg`}
        alt={""}
        width={30}
        height={30}
      />
      <p>{name}</p>
    </div>
  );
};

const DescriptionItem = ({ label, value }) => {
  console.log(value);
  if (!value) {
    return <></>;
  }
  return (
    <div className="Main-Rankings-Description-Item">
      <p className="Main-Rankings-Description-Item-Label">{label}</p>
      <p className="Main-Rankings-Description-Item-Value">
        {`${value[0].toUpperCase()}${value.slice(1)}`}
      </p>
    </div>
  );
};

const AttributesList = ({ attributes, emotion }) => {
  const filters = [
    "Male",
    "Young",
    "Attractive",
    "Brown_Hair",
    "Black_Hair",
    "Blond_Hair",
    "Gray_Hair",
    "Bald",
  ];
  const sortedAttributes = attributes.sort((a, b) => b.percent - a.percent);
  const filteredAttributes = sortedAttributes.filter(
    (att) => !filters.includes(att.name)
  );

  return (
    <>
      <Emotion
        key={emotion}
        name={emotion.charAt(0).toUpperCase() + emotion.slice(1)}
      />
      {filteredAttributes.slice(0, 7).map((attribute) => (
        <Attribute
          key={attribute.name}
          name={attribute.name.replaceAll("_", " ")}
        />
      ))}
    </>
  );
};

const Description = ({
  recomm,
  age,
  gender,
  race,
  attributes,
  emotion,
  haircolor,
}) => {
  // const ageRange = typeof age === "number" ? `${age - 3} - ${age + 3}` : "Unknown";
  const ageRange = age;

  return (
    <div className="Main-Rankings-Descriptions" style={{ gridArea: "a" }}>
      <p className="Main-Rankings-Description">{recomm}</p>

      <div>
        <h4>Description</h4>
        <div className="Main-Rankings-Description-Wrapper">
          <DescriptionItem label={"Age"} value={ageRange} />
          <DescriptionItem label={"Gender"} value={gender} />
          <DescriptionItem label={"Race"} value={race} />
          <DescriptionItem label={"Haircolor"} value={haircolor} />
        </div>
      </div>
      <div>
        <h4>Attributes</h4>
        <div className="Main-Rankings-Attributes-Wrapper">
          <AttributesList attributes={attributes} emotion={emotion} />
        </div>
      </div>
    </div>
  );
};

const Recommendations = ({ recomms }) => {
  return (
    <div className="Recommendations-Wrapper" style={{ gridArea: "c" }}>
      <h4>Recommendations</h4>

      {recomms.map((recomm) => {
        return (
          <Collapsable
            key={recomm.title}
            type={recomm.status}
            label={recomm.title}
            description={recomm.content}
          />
        );
      })}
    </div>
  );
};

const RankingSection = ({ image, selectedPerson }) => {
  const person = image.persons[selectedPerson];

  return (
    <div className="Main-Persons-Section">
      <h2>Ranking</h2>

      {selectedPerson > image.persons.length - 1 ? (
        <></>
      ) : (
        <div className="Main-Rankings">
          <Description
            recomm={person.sympathy.recomm}
            attributes={person.attributes}
            age={person.emotions[0].age}
            gender={person.emotions[0].dominant_gender}
            race={person.emotions[0].dominant_race}
            emotion={person.emotions[0].dominant_emotion}
            haircolor={person.emotions[0].hair_color}
          />
          <Scores
            sympathy={person.sympathy}
            imageQuality={person.imageQuality}
            beauty={person.beauty}
            bodyPosture={person.faceQuality}
            facialEmotions={person.emotions[0].facial_emotion_Score}
          />
          <Recommendations
            recomms={[person.beauty.recomm, person.faceQuality.recomm]}
          />
        </div>
      )}
    </div>
  );
};

export default RankingSection;
