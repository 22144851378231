import { useContext, useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import Dashboard from "./DashboardScreen";
import Login from "./LoginScreen";

import "./App.css";
import { OverlayRoot } from "../components/OverlayRoot";
import { useOverlayStore } from "../stores/OverlayStore/useOverlayStore";

function App() {
  const { authState } = useContext(AuthContext); //Enable the use of the AuthContext
  const hideOverlay = useOverlayStore((state) => state.hideOverlay);

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };

    appHeight();

    window.addEventListener("resize", appHeight);

    return () => {
      window.removeEventListener("resize", appHeight);
    };
  }, []);

  useEffect(() => {
    if (!authState) {
      hideOverlay();
    }
  }, [authState]);

  return (
    <div className="App">
      <OverlayRoot />
      {!authState ? ( //Based on the variable, the corresponding page is displayed
        <>
          <img
            src={"./stickerBOMBArt.svg"}
            onLoad={(evt) => (evt.target.style.opacity = "0.30")}
            className="App-Logo-Login"
            alt="logo"
          />
          <Login />
        </>
      ) : (
        <>
          <Dashboard />
        </>
      )}
    </div>
  );
}

export default App;
