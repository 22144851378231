import { useState } from "react";

import HistoryList from "./components/HistoryList/HistoryList";
import UploadField from "./components/UploadField/UploadField";
import Header from "./components/Header/Header";
import Main from "./components/Main/Main";

import "./dashboard.css";

function Dashboard() {
  const [state, setState] = useState(false);
  const [animate, setAnimate] = useState(false);

  const toggle = () => {
    if (state) {
      setTimeout(() => {
        setAnimate(false);
      }, 500);
    } else {
      setAnimate(true);
    }

    setState((state) => !state);
  };

  return (
    <div className="Dashboard">
      <div
        className={`Dashboard-Sidebar ${
          state ? "Dashboard-Sidebar-Open" : ""
        } ${animate ? "Dashboard-Sidebar-Animation" : ""}`}
      >
        <UploadField className="Dashboard-Upload" />
        <HistoryList className="Dashboard-History" toggle={toggle} />
      </div>

      <div className="Dashboard-Content">
        <Header className="Dashboard-Header" />
        <Main className="Dashboard-Main" />
      </div>
    </div>
  );
}

export default Dashboard;
